import {
  createElement as h,
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { render } from "react-dom";

const videoId = "db5f-A-vSyw";
const dropTimeSeconds = 45;

const debugMode = window.location.search.indexOf("debug") >= 0;
const lockedMode = window.location.search.indexOf("locked") >= 0;

// const midnight = new Date(new Date().setHours(24, 0, 0, 0));
const midnight = new Date("2023-12-31T24:00:00");
const debugTime = new Date(
  new Date().setSeconds(new Date().getSeconds() + dropTimeSeconds + 20)
);

const upcomingYear = midnight.getFullYear();

const initialState = {
  currentTime: new Date(),
  targetTime: debugMode || lockedMode ? debugTime : midnight,
  secondsLeft: Infinity,
  clock: {
    hours: "00",
    minutes: "00",
    seconds: "00",
  },
};

function reducer(
  state: typeof initialState,
  action: { type: "update_time"; currentTime: Date }
): typeof initialState {
  if (action.type === "update_time") {
    const secondsLeft = Math.max(
      0,
      Math.floor(
        (state.targetTime.getTime() - state.currentTime.getTime()) / 1000
      )
    );
    return {
      ...state,
      currentTime: action.currentTime,
      secondsLeft,
      clock: {
        hours: Math.floor(secondsLeft / 60 / 60)
          .toString()
          .padStart(2, "0"),
        minutes: (Math.floor(secondsLeft / 60) % 60)
          .toString()
          .padStart(2, "0"),
        seconds: ((Math.floor(secondsLeft) % 60) % 60)
          .toString()
          .padStart(2, "0"),
      },
    };
  }
  return state;
}

function useYoutubePlayerApi() {
  const [yt, setYt] = useState(null);

  useEffect(() => {
    window.onYouTubePlayerAPIReady = () => {
      setYt(YT);
    };

    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/player_api";
    document.body.appendChild(tag);
  }, []);

  return yt;
}

function Yay() {
  const [variation, setVariation] = useState(0);
  useEffect(() => {
    function tick() {
      setVariation((prev) => (prev + 1) % 4);
    }
    tick();
    const interval = setInterval(tick, 200);
    return function cleanup() {
      clearInterval(interval);
    };
  }, []);
  return h(
    "span",
    { className: `timer crazy-${variation + 1}` },
    h("span", { className: "timer__number" }, "!!!"),
    h("span", { className: "timer__number" }, "!!!"),
    h("span", { className: "timer__number" }, upcomingYear),
    h("span", { className: "timer__number" }, "!!!"),
    h("span", { className: "timer__number" }, "!!!")
  );
}

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const timerInterval = state.secondsLeft <= 60 ? 333 : 1000;
  useEffect(() => {
    function tick() {
      dispatch({ type: "update_time", currentTime: new Date() });
    }
    tick();
    if (!lockedMode) {
      const interval = setInterval(tick, timerInterval);
      return function cleanup() {
        clearInterval(interval);
      };
    }
  }, [timerInterval]);

  const YT = useYoutubePlayerApi();
  const videoWrapperRef = useRef();

  const player = useMemo(() => {
    if (YT && videoWrapperRef.current) {
      return new YT.Player(videoWrapperRef.current, {
        height: "360",
        width: "640",
        videoId,
        playerVars: {
          // controls: 0,
        },
      });
    }
  }, [YT, videoWrapperRef.current]);

  const [controlled, setControlled] = useState(false);
  const STATE_UNSTARTED = -1;
  const STATE_ENDED = 0;
  const STATE_PLAYING = 1;
  const STATE_PAUSED = 2;
  const STATE_BUFFERING = 3;
  const STATE_VIDEO_CUED = 5;
  useEffect(() => {
    if (player && player.getPlayerState) {
      const playerState = player.getPlayerState();
      if (
        state.secondsLeft <= dropTimeSeconds &&
        playerState !== STATE_PLAYING
      ) {
        setControlled(true);
        player.seekTo(dropTimeSeconds - state.secondsLeft);
        player.playVideo();
      }
    }
  }, [player, player && player.getPlayerState, state.secondsLeft]);

  useEffect(() => {
    if (
      window.fathom &&
      state.secondsLeft <= 0 &&
      player &&
      player.getPlayerState &&
      player.getPlayerState() == STATE_PLAYING
    ) {
      window.fathom.trackGoal("JAPLLESL", 0);
    }
  }, [state.secondsLeft <= 0]);

  const playerState =
    player && player.getPlayerState && player.getPlayerState();
  const [hasBeenPlayed, setHasBeenPlayed] = useState(false);
  useEffect(() => {
    if (
      !hasBeenPlayed &&
      typeof playerState === "number" &&
      playerState !== STATE_VIDEO_CUED
    ) {
      setHasBeenPlayed(true);
    }
  }, [playerState, hasBeenPlayed, state.secondsLeft]);
  const steps = [
    {
      text: "Click the video above so that it begins playing. This is just to make sure it's loaded beforehand.",
      showAlert: !controlled && !hasBeenPlayed,
      showDone: hasBeenPlayed,
    },
    { text: "(If there's an advertisement, skip it)" },
    {
      text: "Then pause the video. Doesn't matter at which point, it'll reset to correct time when time is near.",
      showAlert: !controlled && hasBeenPlayed && playerState !== STATE_PAUSED,
      showDone: hasBeenPlayed && playerState === STATE_PAUSED,
    },
    { text: "Leave the tab open and wait until midnight!" },
  ];

  return h(
    "div",
    null,
    // h('p', null, `Your local time: ${formatTime(state.currentTime)}`),
    debugMode &&
      h(
        "p",
        { style: { color: "yellow", fontWeight: "bold" } },
        'You are viewing a debug version. When you refresh this page the "midnight timer" will always be set to 30 seconds before the video should start.'
      ),
    state.secondsLeft > 0
      ? h(
          Fragment,
          null,
          h("p", null, "Time until midnight:"),
          h(
            "p",
            null,
            h(
              "span",
              { className: "timer" },
              h(
                "span",
                { className: "timer__number", title: "hours" },
                state.clock.hours
              ),
              h("span", { className: "timer__divider" }, ":"),
              h(
                "span",
                { className: "timer__number", title: "minutes" },
                state.clock.minutes
              ),
              h("span", { className: "timer__divider" }, ":"),
              h(
                "span",
                { className: "timer__number", title: "seconds" },
                state.clock.seconds
              )
            )
          )
        )
      : h(
          Fragment,
          null,
          h("p", null, "Happy New Year!"),
          h("p", null, h(Yay))
        ),
    h("div", { ref: videoWrapperRef }),
    h("p", null, `Give your ${upcomingYear} an epic start`),
    h("p", { className: "small" }, "Instructions:"),
    h(
      "ol",
      { className: "small" },
      steps.map((step, index) =>
        h(
          "li",
          {
            key: index,
            className: [
              step.showAlert ? "li--alert" : "",
              step.showDone ? "li--done" : "",
            ].join(" "),
          },
          step.text
        )
      )
    ),
    h(
      "p",
      { className: "small" },
      `At 45 seconds before midnight, this website will start playing the video, and the epic drop in the song will happen precisely at midnight! Seconds left until video starts: ${Math.max(
        0,
        state.secondsLeft - dropTimeSeconds
      )}`
    ),
    h(
      "p",
      { className: "small" },
      "Note: this tab must be open for the timer to work."
    ),
    h(
      "p",
      { className: "small" },
      "Sorry for these steps but it's just to make sure that the video will play without buffering or ads!"
    ),
    h(
      "p",
      { className: "small" },
      "This probably doesn't work on mobile devices because of the way they play video."
    )
    // h(
    //   'p',
    //   { className: 'small' },
    //   'Links to this page: ',
    //   h('a', { href: 'https://sandstorm.lol' }, 'sandstorm.lol'),
    //   ' ',
    //   h('a', { href: 'https://epicnewyears.party' }, 'epicnewyears.party')
    // )
  );
};

render(h(App), document.querySelector(".app"));
